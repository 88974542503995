.add-form {
    width: 40vw;
    margin: 50px auto;
}

.add-form label {
    font-weight: bold;
    margin-top: 20px;
}

.add-form input, textarea {
    width: 100%;
    margin-top: 10px;
    padding: 0.3rem .7rem;

}

.add-form button {

    width: 100%;

}
