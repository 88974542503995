header {
    background-color: #6200ee;
    padding: 10px;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

header h1 {
    font-size: 20px;
    font-weight: normal;
}
